import { Tooltip } from 'antd';
import React from 'react';
import clsx from 'clsx';
import styles from './AppMenu.module.scss';
import { MenuItemConfig } from '../interfaces/menu-item-config';
import { useAppSelector } from '../../../app/providers/StoreProvider/hooks/hooks';

interface SiderMenuProps {
  readonly menuItems: MenuItemConfig[];
  readonly menuSelectionEvent: (key: string) => void;
  readonly defaultSelected?: string;
  readonly highlightSelected?: boolean;
}

const AppMenu: React.FC<SiderMenuProps> = ({
  menuItems,
  menuSelectionEvent,
  defaultSelected = '',
  highlightSelected = true,
}) => {
  const [current, setCurrent] = React.useState(defaultSelected);
  const isExpanded = useAppSelector((state) => state.siderState.isExpanded);

  React.useEffect(() => {
    setCurrent(defaultSelected);
  }, [defaultSelected]);

  const onClick = (key: string): void => {
    menuSelectionEvent(key);
    setCurrent(key);
  };

  const isSelected = (key: string): boolean => key === current;

  const menuItemClass = (key: string): string =>
    clsx(styles.menuItem, { [styles.selected]: isSelected(key) && highlightSelected });

  const iconClass = (): string => clsx(styles.icon, { [styles.collapsed]: !isExpanded });

  return (
    <div className={styles.menuItemsContainer}>
      {menuItems.map((menuItem) => {
        return (
          <Tooltip placement="right" title={!isExpanded ? menuItem.title : ''} key={menuItem.key}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              className={menuItemClass(menuItem.key)}
              onClick={(): void => onClick(menuItem.key)}
            >
              {!!menuItem?.count && (
                <div className={styles.badge}>
                  <span
                    className={
                      menuItem.count?.toString().length > 2
                        ? clsx([styles.badgeText, styles.largeBadgeText])
                        : styles.badgeText
                    }
                  >
                    {menuItem.count}
                  </span>
                </div>
              )}
              <div className={iconClass()}>{menuItem.icon}</div>
              {isExpanded && (
                <div style={{ fontSize: '1rem', marginLeft: '10px', overflow: 'hidden' }}>
                  {menuItem.title}
                </div>
              )}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default AppMenu;
