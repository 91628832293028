import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from '../../../environments/env';
import { LoginProps, LoginSentResponse } from '../../../features/AuthByUserName/model/types/types';

const URL = env.REACT_APP_AUTH_URL;
const loginPath = 'token_users';

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: fetchBaseQuery({
    baseUrl: URL,
    prepareHeaders: (headers) => {
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/x-www-form-urlencoded');
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginSentResponse, LoginProps>({
      query: (credentials) => {
        return {
          url: loginPath,
          method: 'POST',
          body: new URLSearchParams({
            username: credentials.username,
            password: credentials.password,
          }),
        };
      },
    }),
  }),
});

export const { useLoginMutation } = loginApi;
