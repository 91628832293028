import React from 'react';
import useDashboardData from '../../features/Dashboard/model/hooks/useDashboardData';
import DashboardContext from '../context/DashboardContext';
import { useAppDispatch } from '../../app/providers/StoreProvider/hooks/hooks';
import { actions as dashboardActions } from '../../features/Dashboard/model/state/dashboard-slice';

interface DashboardProviderProps {
  readonly children: React.ReactElement;
}

const DashboardProvider: React.FC<DashboardProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const data = useDashboardData(true);

  React.useEffect(() => {
    const timeout = setTimeout(() => dispatch(dashboardActions.setFirstRequest(false)), 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  React.useEffect(() => {
    dispatch(dashboardActions.setDashboardState(data.data));
  }, [data.data]);

  return <DashboardContext.Provider value={data}>{children}</DashboardContext.Provider>;
};

export default DashboardProvider;
