import { Dispatch, createContext } from 'react';
import { IDashboard } from '../../features/Dashboard/model/types/DashboardInfo';

const DashboardContext = createContext<{
  data: IDashboard | null;
  loading: boolean;
  setData: Dispatch<IDashboard | null>;
}>({
  data: null,
  loading: false,
  setData: () => {},
});

export default DashboardContext;
