import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import createPath from '../../../../shared/helpers/createPath';
import { env } from '../../../../environments/env';
import getLoginData from '../../../../shared/helpers/getLoginData';
import IControlActivityData from '../../model/interfaces/IControlActivityData';
import IControlActivityMapData from '../../model/interfaces/IControlActivityMapData';

const api = env.REACT_APP_API_URL;

export const activityApi = createApi({
  reducerPath: 'api/activity',
  baseQuery: fetchBaseQuery({
    baseUrl: createPath(api, 'activity'),
    prepareHeaders: (headers) => {
      const { token } = getLoginData();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getDepartmentInfo: build.query<
      IControlActivityData,
      {
        departmentId?: number;
        departmentLevel?: number;
      }
    >({
      query: (params) => ({
        url: '/info/departments/',
        method: 'GET',
        params: {
          department_id: params.departmentId,
          department_level: params.departmentLevel,
        },
      }),
      transformResponse: (response: { activity: IControlActivityData }): IControlActivityData => {
        return response.activity;
      },
    }),

    getDepartmentMap: build.query<
      IControlActivityMapData,
      {
        departmentId?: number;
        departmentLevel?: number;
      }
    >({
      query: (params) => ({
        url: '/info/map/',
        method: 'GET',
        params: {
          department_id: params.departmentId,
          department_level: params.departmentLevel,
        },
      }),
    }),
  }),
});

export const { useGetDepartmentInfoQuery, useGetDepartmentMapQuery } = activityApi;
