import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { AppHeader } from '../../../Header';
import AppSider from '../../../AppSider/ui/components/AppSider';
import NavMenu from '../../../NavMenu/ui/components/NavMenu';
import UserMenu from '../../../UserMenu/ui/components/UserMenu';
import { useIncludesLocal } from '../../../../shared/helpers/useIncludesLocal';
import styles from './AppLayout.module.scss';

interface AppLayoutProps {
  readonly children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const isPrivatePage = useIncludesLocal();

  return (
    <Layout className={styles.mainLayout}>
      <AppHeader />
      <Layout className={styles.layout}>
        {isPrivatePage && (
          <AppSider>
            <div className={styles.sider}>
              <NavMenu />
              <UserMenu />
            </div>
          </AppSider>
        )}
        <Content className={styles.content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
