import React from 'react';
import icon from '../source/controlActivity.png';

const ControlActivityIcon: React.FC<
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = ({ alt, ...attrs }) => {
  return <img {...attrs} src={icon} alt={alt || 'image'} />;
};

export default ControlActivityIcon;
