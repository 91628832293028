import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import AppMenu from '../../../AppMenu/components/AppMenu';
import { MENU_ITEMS_CONFIG } from '../../constants/MENU_ITEMS_CONFIG';
import { IDashboard } from '../../../../features/Dashboard/model/types/DashboardInfo';
import getPlatformConfig from '../../../../shared/helpers/getPlatformConfig';
import { useAppSelector } from '../../../../app/providers/StoreProvider/hooks/hooks';

const NavMenu: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleMenuSelection = (key: string): void => {
    navigate(`/${key}`);
  };
  const [menuItems, setMenuItems] = React.useState(
    MENU_ITEMS_CONFIG.filter(
      (item) => (getPlatformConfig() as any)?.[item?.key] || item?.key === 'dashboard'
    )
  );

  const [currLocation, setCurrLocation] = React.useState('');
  const { data } = useAppSelector((state) => state.dashboardState);

  React.useEffect(() => {
    const currLoc =
      MENU_ITEMS_CONFIG.find((item) => location.pathname.includes(item.route))?.key || 'dashboard';

    if (currLoc) setCurrLocation(currLoc);
  }, [location]);

  React.useEffect(() => {
    const menuItemsWithCount = menuItems.map((menuItem) => {
      const key = menuItem.key as keyof IDashboard;
      return {
        ...menuItem,
        count: data ? data[key]?.warnings : 0,
      };
    });

    setMenuItems(menuItemsWithCount);
  }, [data]);
  return (
    <AppMenu
      menuItems={menuItems}
      menuSelectionEvent={handleMenuSelection}
      defaultSelected={currLocation}
    />
  );
};

export default NavMenu;
