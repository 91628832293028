import Sider from 'antd/es/layout/Sider';
import React from 'react';
import styles from './AppSider.module.scss';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../app/providers/StoreProvider/hooks/hooks';
import { actions as siderActions } from '../../store/siderSlice';

interface AppSiderProps {
  readonly children: React.ReactNode;
}

const AppSider: React.FC<AppSiderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const isExpanded = useAppSelector((state) => state.siderState.isExpanded);

  const setExpanded = (isExpanded: boolean): void => {
    dispatch(siderActions.setExpanded(!isExpanded));
  };

  return (
    <Sider
      className={styles.sider}
      collapsible
      collapsed={!isExpanded}
      onCollapse={(value): void => setExpanded(value)}
      width={250}
    >
      {children}
    </Sider>
  );
};

export default AppSider;
