import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isExpanded: true,
};

const siderSlice = createSlice({
  name: 'sider',
  initialState,
  reducers: {
    setExpanded: (state, { payload }) => {
      const currentState = state;
      currentState.isExpanded = payload;
    },
  },
});

export const { actions } = siderSlice;

export default siderSlice.reducer;
