import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../../../../shared/store/user-slice';
import { reportsApi } from '../../../../features/Report/api/services/reportsApi';
import siderReducer from '../../../../components/AppSider/store/siderSlice';
import dashboardReducer from '../../../../features/Dashboard/model/state/dashboard-slice';
import { settingsApi } from '../../../../features/Settings/api/service/allSettings.api';
import { hardwareApi } from '../../../../features/Hardware/api/hardware.api';
import { activityApi } from '../../../../features/ControlActivity/api/services/activityApi';
import { loginApi } from '../../../../pages/LoginPage/api/login.api';
import { personSearchApi } from '../../../../shared/api/person-search.api';
import { hardwareSteamsApi } from '../../../../features/Hardware/api/hardware-steams.api';
import { hardwareVideoApi } from '../../../../features/Hardware/api/hardware-video.api';

export function createReduxStore() {
  const rootReducer = {
    siderState: siderReducer,
    userState: userReducer,
    dashboardState: dashboardReducer,
    [hardwareApi.reducerPath]: hardwareApi.reducer,
    [hardwareSteamsApi.reducerPath]: hardwareSteamsApi.reducer,
    [hardwareVideoApi.reducerPath]: hardwareVideoApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [activityApi.reducerPath]: activityApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [personSearchApi.reducerPath]: personSearchApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
  };

  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      hardwareApi.middleware,
      hardwareSteamsApi.middleware,
      hardwareVideoApi.middleware,
      loginApi.middleware,
      reportsApi.middleware,
      personSearchApi.middleware,
      settingsApi.middleware,
      activityApi.middleware,
    ],
  });
}

export const store = createReduxStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
