import { type RouteProps } from 'react-router-dom';
import { Dashboard } from '../../../pages/Dashboard';
import { ControlActivity } from '../../../pages/ControlActivity';
import { ControlDoors } from '../../../pages/ControlDoors';
import { SteamControl } from '../../../pages/SteamControl';
import { Reports } from '../../../pages/Reports';
import { Hardware } from '../../../pages/Hardware';
import { Settings } from '../../../pages/Settings';
import { ControlPpe } from '../../../pages/ControlPpe';
import { NotFoundPage } from '../../../pages/NotFoundPage';
import { LoginPage } from '../../../pages/LoginPage';
import Exit from '../../../pages/Exit';
import { ControlAccess } from '../../../pages/ControlAccess';

export enum AppRoutes {
  LOGIN = 'login',
  NOT_FOUND = 'not_found',
  EXIT = 'exit',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.LOGIN]: '/login',
  [AppRoutes.NOT_FOUND]: '*',
  [AppRoutes.EXIT]: '/exit',
};

export enum AppPrivateRoutes {
  DASHBOARD = 'dashboard',
  MAIN_DASHBOARD = 'main_dashboard',
  ACTIVITY_CONTROL = 'activityControl',
  DOORS_CONTROL = 'doorsControl',
  STREAM_CONTROL = 'steamControl',
  REPORTS = 'reports',
  HARDWARE = 'hardware',
  SETTINGS = 'settings',
  PPE_CONTROL = 'videoControl',
  ACCESS_CONTROL = 'accessControl',
}

export const RoutePrivatePath: Record<AppPrivateRoutes, string> = {
  [AppPrivateRoutes.DASHBOARD]: '/dashboard',
  [AppPrivateRoutes.MAIN_DASHBOARD]: '/',
  [AppPrivateRoutes.ACTIVITY_CONTROL]: '/activityControl',
  [AppPrivateRoutes.DOORS_CONTROL]: '/doorsControl',
  [AppPrivateRoutes.STREAM_CONTROL]: '/steamControl',
  [AppPrivateRoutes.ACCESS_CONTROL]: '/accessControl',
  [AppPrivateRoutes.REPORTS]: '/reports',
  [AppPrivateRoutes.HARDWARE]: '/hardware',
  [AppPrivateRoutes.SETTINGS]: '/settings',
  [AppPrivateRoutes.PPE_CONTROL]: '/videoControl',
};

export const routePrivateConfig: Record<AppPrivateRoutes, RouteProps> = {
  [AppPrivateRoutes.DASHBOARD]: {
    path: RoutePrivatePath.dashboard,
    element: <Dashboard />,
  },
  [AppPrivateRoutes.MAIN_DASHBOARD]: {
    path: RoutePrivatePath.main_dashboard,
    element: <Dashboard />,
  },
  [AppPrivateRoutes.ACTIVITY_CONTROL]: {
    path: RoutePrivatePath.activityControl,
    element: <ControlActivity />,
  },
  [AppPrivateRoutes.DOORS_CONTROL]: {
    path: RoutePrivatePath.doorsControl,
    element: <ControlDoors />,
  },
  [AppPrivateRoutes.STREAM_CONTROL]: {
    path: RoutePrivatePath.steamControl,
    element: <SteamControl />,
  },
  [AppPrivateRoutes.ACCESS_CONTROL]: {
    path: RoutePrivatePath.accessControl,
    element: <ControlAccess />,
  },
  [AppPrivateRoutes.REPORTS]: {
    path: RoutePrivatePath.reports,
    element: <Reports />,
  },
  [AppPrivateRoutes.HARDWARE]: {
    path: RoutePrivatePath.hardware,
    element: <Hardware />,
  },
  [AppPrivateRoutes.SETTINGS]: {
    path: RoutePrivatePath.settings,
    element: <Settings />,
  },
  [AppPrivateRoutes.PPE_CONTROL]: {
    path: RoutePrivatePath.videoControl,
    element: <ControlPpe />,
  },
};

export const routeConfig: Record<AppRoutes, RouteProps> = {
  [AppRoutes.LOGIN]: {
    path: RoutePath.login,
    element: <LoginPage />,
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath.not_found,
    element: <NotFoundPage />,
  },
  [AppRoutes.EXIT]: {
    path: RoutePath.exit,
    element: <Exit />,
  },
};
