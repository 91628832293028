import { useContext, useEffect } from 'react';
import { SocketConnectionProps } from '../types/types';
import getLoginData from '../helpers/getLoginData';
import SocketContext from '../context/SocketContext';

const useSocketConnection = ({
  onmessage,
}: {
  onmessage: (event: MessageEvent<any>) => void;
}): { sendMessage: (socketProps: SocketConnectionProps) => void } => {
  const loginData = getLoginData();
  const { socket } = useContext(SocketContext);

  const sendMessage = (socketProps: SocketConnectionProps) => {
    if (socket?.OPEN) {
      socket?.send(
        JSON.stringify({
          token: loginData.token,
          ...socketProps,
        })
      );
    }
  };

  useEffect(() => {
    if (socket?.OPEN) socket?.addEventListener('message', onmessage);

    return () => {
      socket?.removeEventListener('message', onmessage);
    };
  }, [socket?.OPEN]);

  return { sendMessage };
};

export default useSocketConnection;
