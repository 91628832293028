import { useNavigate } from 'react-router-dom';
import Typography from '../shared/ui/Typography/Typography';
import Button from '../shared/ui/Button/Button';

const Exit = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography type="h3">
        <Typography>Вы вышли из программы</Typography>
      </Typography>
      <Button style={{ marginTop: 20 }} onClick={() => navigate('/login')}>
        Войти заново
      </Button>
    </div>
  );
};

export default Exit;
