import React from 'react';
import '../styles/index.scss';
import { AppRouter } from './providers/router';
import AppLayout from '../components/Layout/ui/AppLayout/AppLayout';
import useBeforeUnloadEvent from '../shared/hooks/useBeforeUnloadEvent';

const App: React.FC = () => {
  useBeforeUnloadEvent();

  return (
    <div className="App">
      <div className="content-page">
        <AppLayout>
          <AppRouter />
        </AppLayout>
      </div>
    </div>
  );
};

export default App;
