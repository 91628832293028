import getLoginData from './getLoginData';
import { env } from '../../environments/env';

const exit = () => {
  const { token } = getLoginData();

  return fetch(`${env.REACT_APP_API_URL}/exit`, {
    method: 'POST',
    headers: { Authorization: `Bearer ${token}` },
    keepalive: true,
  });
};
export default exit;
