import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { env } from '../../environments/env';
import createPath from '../helpers/createPath';
import { RootState } from '../../app/providers/StoreProvider/config/store';

const api = env.REACT_APP_API_URL;

const personUrl = 'persons/';

export const personSearchApi = createApi({
  reducerPath: 'personSearch',
  baseQuery: fetchBaseQuery({
    baseUrl: createPath(api, personUrl),
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userState.access_token;
      headers.set('Authorization', `Bearer ${token}`);
    },
  }),
  endpoints: (builder) => ({
    findPersons: builder.query<
      { persons: { id: number; name: string }[] },
      { name: string; lineId?: string; srpId?: string }
    >({
      query: (args) => ({
        url: '',
        method: 'GET',
        params: { name: args.name, line_id: args?.lineId, srp_id: args?.srpId },
        credentials: 'include',
      }),
    }),
  }),
});

export const { useLazyFindPersonsQuery, useFindPersonsQuery } = personSearchApi;
