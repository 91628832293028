import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import dayjs from 'dayjs';
import axios, { AxiosResponse } from 'axios';
import createPath from '../../../shared/helpers/createPath';
import { env } from '../../../environments/env';
import serializeParams from '../helpers/serializeParams';
import IVideoInfo from '../types/IVideoInfo';
import { DateFormats } from '../../../shared/types/types';
import getLoginData from '../../../shared/helpers/getLoginData';

const api = env.REACT_APP_API_URL;

export const hardwareVideoApi = createApi({
  reducerPath: 'hardwareVideoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: createPath(api, 'media', 'videos', 'archive'),
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${getLoginData().token}`);
    },
  }),
  endpoints: (builder) => ({
    getEarliestDatetime: builder.mutation<{ earliestTime: string }, { camera_id: number }>({
      query: (params) => ({
        url: '/earliest_datetime',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useGetEarliestDatetimeMutation } = hardwareVideoApi;

export const getVideoInfo = (
  cameraId: number,
  startTime: dayjs.Dayjs
): Promise<AxiosResponse<IVideoInfo>> => {
  const params = {
    camera_id: cameraId,
    start_time: dayjs(startTime).format(DateFormats.request),
  } as { camera_id: number; start_time?: string };

  return axios.get<IVideoInfo>(
    `${createPath(api, 'media', 'videos', 'archive', 'params')}${serializeParams(params)}`
  );
};
