import { USER_LOCALSTORAGE_KEY } from '../const/localstorage';
import { LoginData } from '../types/types';

export default (): LoginData => {
  // eslint-disable-next-line camelcase
  const { access_token, token_type, fullName, platforms } = JSON.parse(
    localStorage.getItem(USER_LOCALSTORAGE_KEY) || '[]'
  ) as {
    access_token: string;
    token_type: string;
    fullName: string;
    platforms: { id: number; name: string; description: null }[];
  };

  return {
    // eslint-disable-next-line camelcase
    token: access_token,
    // eslint-disable-next-line camelcase
    tokenType: token_type,
    userName: fullName,
    platformsStorage: platforms,
  };
};
