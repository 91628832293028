import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppMenu from '../../../AppMenu/components/AppMenu';
import { useOnLogOutClick } from '../../../../shared/hooks/useOnLogOutClick';
import { MENU_ITEMS_CONFIG } from '../../constants/MENU_ITEMS_CONFIG';

const UserMenu: React.FC = () => {
  const { onLogOutClick } = useOnLogOutClick();
  const navigate = useNavigate();

  return (
    <AppMenu
      menuItems={MENU_ITEMS_CONFIG}
      menuSelectionEvent={() => {
        onLogOutClick();
        navigate('/exit');
      }}
      highlightSelected={false}
    />
  );
};

export default UserMenu;
