import React from 'react';
import icon from '../source/hardware.png';

const HardwareIcon: React.FC<
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = ({ alt, ...attrs }) => {
  return <img {...attrs} src={icon} alt={alt || 'image'} />;
};

export default HardwareIcon;
