import { EFKO_PLATFORM, USER_CONFIG, USER_LOCALSTORAGE_KEY } from '../const/localstorage';
import { useAuth } from './useAuth';
import exit from '../helpers/exit';

export const useOnLogOutClick = (): { onLogOutClick: (exitByServer?: boolean) => void } => {
  const { setAuth } = useAuth();

  const onLogOutClick = (exitByServer = true): void => {
    if (exitByServer) {
      exit();
    }
    localStorage.removeItem(USER_LOCALSTORAGE_KEY);
    localStorage.removeItem(USER_CONFIG);
    localStorage.removeItem(EFKO_PLATFORM);
    setAuth(false);
  };
  return { onLogOutClick };
};
