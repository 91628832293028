import { type FC } from 'react';
import clsx from 'clsx';
import styles from './Button.module.scss';
import { ButtonProps } from '../../types/types';

const Button: FC<ButtonProps> = (props) => {
  const {
    children,
    className: currentClassName,
    type = 'button',
    view = 'primary',
    disabled = false,
    withoutIndentation = false,
    ...otherProps
  } = props;

  const className = clsx(styles.root, currentClassName, {
    [styles[`root_${view}`]]: view,
    [styles.root_withoutIndentation]: withoutIndentation,
  });

  return (
    <button type={type} disabled={disabled} {...otherProps} className={className}>
      {children}
    </button>
  );
};
export default Button;
