import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from '../../../environments/env';
import createPath from '../../../shared/helpers/createPath';
import { BadgeFormData } from '../types/badge-form-data';
import { CameraFormData } from '../types/camera-form-data';
import { DoorSensorFormData } from '../types/door-sensor-form-data';
import getLoginData from '../../../shared/helpers/getLoginData';

const api = env.REACT_APP_API_URL;

const badgeUrl = 'badge/';
const cameraUrl = 'camera/';
const doorSensorsUrl = 'door/';
const personsUrl = 'persons/';

export const hardwareApi = createApi({
  reducerPath: 'hardwareApi',
  baseQuery: fetchBaseQuery({
    baseUrl: createPath(api, 'hardware'),
    prepareHeaders: (headers) => {
      const { token } = getLoginData();
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addBadge: builder.mutation<{ status: boolean; baigeId: number }, BadgeFormData>({
      query: (badge) => ({
        url: badgeUrl,
        method: 'POST',
        body: badge,
      }),
    }),
    editBadge: builder.mutation<{ status: boolean }, BadgeFormData>({
      query: (badge) => ({
        url: badgeUrl,
        method: 'PUT',
        body: badge,
      }),
    }),
    deleteBadge: builder.mutation<boolean, BadgeFormData>({
      query: (badge) => ({
        url: createPath(badgeUrl, String(badge.baigeId)),
        method: 'DELETE',
      }),
    }),
    addCamera: builder.mutation<{ status: boolean; cameraId: number }, CameraFormData>({
      query: (camera: CameraFormData) => ({
        url: cameraUrl,
        method: 'POST',
        body: camera,
      }),
    }),
    editCamera: builder.mutation<{ status: boolean }, CameraFormData>({
      query: (camera: CameraFormData) => ({
        url: cameraUrl,
        method: 'PUT',
        body: camera,
      }),
    }),
    deleteCamera: builder.mutation<{ status: boolean }, number>({
      query: (cameraId: number) => ({
        url: `${cameraUrl}${String(cameraId)}`,
        method: 'DELETE',
      }),
    }),
    addDoorSensor: builder.mutation<{ status: boolean; doorId: number }, DoorSensorFormData>({
      query: (doorSensor: DoorSensorFormData) => ({
        url: doorSensorsUrl,
        method: 'POST',
        body: doorSensor,
      }),
    }),
    editDoorSensor: builder.mutation<{ status: boolean }, DoorSensorFormData>({
      query: (doorSensor: DoorSensorFormData) => ({
        url: doorSensorsUrl,
        method: 'PUT',
        body: doorSensor,
      }),
    }),
    deleteDoorSensor: builder.mutation<{ status: boolean }, number>({
      query: (doorSensorId: number) => ({
        url: createPath(doorSensorsUrl, String(doorSensorId)),
        method: 'DELETE',
      }),
    }),
    updatePersonBadge: builder.query<
      { status: boolean },
      { badgeId: number | null; personId: number }
    >({
      query: (payload) => ({
        url: personsUrl,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const {
  useAddBadgeMutation,
  useDeleteBadgeMutation,
  useEditBadgeMutation,
  useAddCameraMutation,
  useEditCameraMutation,
  useDeleteCameraMutation,
  useAddDoorSensorMutation,
  useEditDoorSensorMutation,
  useDeleteDoorSensorMutation,
  useLazyUpdatePersonBadgeQuery,
} = hardwareApi;
