import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import createPath from '../../../../shared/helpers/createPath';
import { env } from '../../../../environments/env';
import getLoginData from '../../../../shared/helpers/getLoginData';
import { VideoSettingsPutProps } from '../../types/VideoSettingsTypes/types';
import { ActiveSettingsPutProps } from '../../types/ActiveSettingsTypes/types';
import { SteamSettingsPutProps } from '../../types/SteamSettingsTypes/types';
import { DoorsSettingsPutProps } from '../../types/DoorsSettingsTypes/types';
import { UsersGeneralProps, UsersGeneralPutProps } from '../../types/UserSettingsTypes/types';

const api = env.REACT_APP_API_URL;

export const settingsApi = createApi({
  reducerPath: 'api/settings',
  baseQuery: fetchBaseQuery({
    baseUrl: createPath(api, 'settings'),
    prepareHeaders: (headers) => {
      const { token } = getLoginData();

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    updateActiveSettings: build.mutation<ActiveSettingsPutProps, ActiveSettingsPutProps>({
      query: (settings) => ({
        url: 'activity',
        method: 'PUT',
        body: settings,
      }),
    }),
    updateVideoSettings: build.mutation<VideoSettingsPutProps, VideoSettingsPutProps>({
      query: (settings) => ({
        url: 'video',
        method: 'PUT',
        body: settings,
      }),
    }),
    updateSteamSettings: build.mutation<SteamSettingsPutProps, SteamSettingsPutProps>({
      query: (settings) => ({
        url: 'steam',
        method: 'PUT',
        body: settings,
      }),
    }),
    updateDoorsSettings: build.mutation<DoorsSettingsPutProps, DoorsSettingsPutProps>({
      query: (settings) => ({
        url: 'doors',
        method: 'PUT',
        body: settings,
      }),
    }),
    getUsersSettings: build.query<UsersGeneralProps, Record<string, string>>({
      query: (args) => {
        const { type, id } = args;
        return {
          url: `users/${type}/${id}`,
        };
      },
    }),
    updateUsersSettings: build.mutation<UsersGeneralPutProps, UsersGeneralPutProps>({
      query: (settings) => ({
        url: 'users',
        method: 'PUT',
        body: settings,
      }),
    }),
  }),
});

export const {
  useUpdateActiveSettingsMutation,
  useUpdateVideoSettingsMutation,
  useUpdateSteamSettingsMutation,
  useUpdateDoorsSettingsMutation,
  useUpdateUsersSettingsMutation,
  useGetUsersSettingsQuery,
} = settingsApi;
