import { SiSonarcloud } from 'react-icons/si';
import { TbReportAnalytics } from 'react-icons/tb';
import { MenuItemConfig } from '../../AppMenu/interfaces/menu-item-config';
import { RoutePrivatePath } from '../../../shared/config/routerConfig/routerConfig';
import ControlActivityIcon from '../../../shared/assets/icons/components/ControlActivityIcon';
import ControlDoorsIcon from '../../../shared/assets/icons/components/ControlDoorsIcon';
import DashboardIcon from '../../../shared/assets/icons/components/DashboardIcon';
import SettingsIcon from '../../../shared/assets/icons/components/SettingsIcon';
import HardwareIcon from '../../../shared/assets/icons/components/HardwareIcon';
import ControlVideoIcon from '../../../shared/assets/icons/components/ControlVideoIcon';

export const MENU_ITEMS_CONFIG: MenuItemConfig[] = [
  {
    key: 'dashboard',
    title: 'Дашборд',
    route: RoutePrivatePath.dashboard,
    icon: <DashboardIcon />,
  },
  {
    key: 'activityControl',
    title: 'Контроль активности',
    route: RoutePrivatePath.activityControl,
    icon: <ControlActivityIcon />,
  },
  {
    key: 'videoControl',
    title: 'Контроль СИЗ',
    route: RoutePrivatePath.videoControl,
    icon: <ControlVideoIcon />,
  },
  {
    key: 'doorsControl',
    title: 'Контроль дверей',
    route: RoutePrivatePath.doorsControl,
    icon: <ControlDoorsIcon />,
  },
  {
    key: 'accessControl',
    title: 'Контроль доступа',
    route: RoutePrivatePath.accessControl,
    // TODO: требуется уточнение по иконке камера или новая иконка
    // icon: <ControlAccessIcon />,
    icon: <ControlVideoIcon />,
  },
  {
    key: 'steamControl',
    title: 'Контроль пара',
    route: RoutePrivatePath.steamControl,
    icon: <SiSonarcloud />,
  },
  { key: 'reports', title: 'Отчеты', route: RoutePrivatePath.reports, icon: <TbReportAnalytics /> },
  {
    key: 'hardware',
    title: 'Оборудование',
    route: RoutePrivatePath.hardware,
    icon: <HardwareIcon />,
  },
  {
    key: 'settings',
    title: 'Настройки',
    route: RoutePrivatePath.settings,
    icon: <SettingsIcon />,
  },
];
