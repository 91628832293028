import { createSlice } from '@reduxjs/toolkit';
import { LoginSentResponse } from '../../features/AuthByUserName/model/types/types';
import { USER_LOCALSTORAGE_KEY } from '../const/localstorage';
import { loginApi } from '../../pages/LoginPage/api/login.api';

const initialState: LoginSentResponse = JSON.parse(
  localStorage?.getItem(USER_LOCALSTORAGE_KEY) ?? '{}'
) || {
  access_token: '',
  token_type: '',
  platforms: [],
  fullName: '',
};

const userSlice = createSlice({
  name: 'userState',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(loginApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state = payload;
    });
  },
});

export const { actions } = userSlice;

export default userSlice.reducer;
