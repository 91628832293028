import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import styles from './AppRoutes.module.scss';
import {
  routeConfig,
  routePrivateConfig,
} from '../../../../shared/config/routerConfig/routerConfig';
import PrivateRoute from '../../../../components/PrivateRoute';
import { Loader } from '../../../../shared/ui/Loader/Loader';
import SocketProvider from '../../../../shared/providers/SocketProvider';

const AppRouter = () => {
  return (
    <Routes>
      {Object.values(routeConfig).map(({ path, element }) => (
        <Route
          key={path}
          path={path}
          element={<Suspense fallback={<Loader />}>{element}</Suspense>}
        />
      ))}
      <Route element={<PrivateRoute />}>
        <Route element={<SocketProvider />}>
          {Object.values(routePrivateConfig).map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={
                <Suspense fallback={<Loader />}>
                  <div className={styles.contentWrapper}>{element}</div>
                </Suspense>
              }
            />
          ))}
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRouter;
