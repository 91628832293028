import React from 'react';
import icon from '../source/controlVideo.png';

const ControlVideoIcon: React.FC<
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = ({ alt, ...attrs }) => {
  return <img {...attrs} src={icon} alt={alt || 'image'} />;
};

export default ControlVideoIcon;
