import { useLocation } from 'react-router-dom';
import { RoutePrivatePath } from '../config/routerConfig/routerConfig';

export const useIncludesLocal = (): boolean => {
  const location = useLocation();

  const result = Object.values(RoutePrivatePath).find((local) => local.includes(location.pathname));

  return !!result;
};
