import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import createPath from '../../../shared/helpers/createPath';
import { env } from '../../../environments/env';
import { SteamSensorFormData } from '../types/steam-sensor-form-data';
import { HardwareData } from '../types/hardware-data';
import getLoginData from '../../../shared/helpers/getLoginData';

const api = env.REACT_APP_API_URL;

const steamUrl = 'steam/';

export const hardwareSteamsApi = createApi({
  reducerPath: 'hardwareSteamsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: createPath(api, 'hardware'),
    prepareHeaders: (headers) => {
      const { token } = getLoginData();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addSteamSensor: builder.mutation<{ status: boolean; steamId: number }, SteamSensorFormData>({
      query: (steamSensor) => ({
        url: steamUrl,
        method: 'POST',
        body: steamSensor,
      }),
    }),
    editSteamSensor: builder.mutation<{ status: boolean }, SteamSensorFormData>({
      query: (steamSensor) => ({
        url: steamUrl,
        method: 'PUT',
        body: steamSensor,
      }),
    }),
    deleteSteamSensor: builder.mutation<{ status: boolean }, number>({
      query: (steamSensorId) => ({
        url: `${steamUrl}/${String(steamSensorId)}`,
        method: 'DELETE',
      }),
    }),
    getSteamSensorHistory: builder.query<
      { history: HardwareData['steamSensors']['history'] },
      { sensorId: number; startDate: string; endDate: string }
    >({
      query: ({ sensorId, startDate, endDate }) => ({
        url: steamUrl,
        method: 'GET',
        params: {
          sensor_id: sensorId,
          start_date: startDate,
          end_date: endDate,
        },
      }),
    }),
  }),
});

export const {
  useAddSteamSensorMutation,
  useEditSteamSensorMutation,
  useDeleteSteamSensorMutation,
  useLazyGetSteamSensorHistoryQuery,
} = hardwareSteamsApi;
