import { Header } from 'antd/es/layout/layout';
import React from 'react';
import styles from './Header.module.scss';
import { useAuth } from '../../../shared/hooks/useAuth';
import Typography from '../../../shared/ui/Typography/Typography';
import { DataTime } from './components/DataTime/DataTime';
import getLoginData from '../../../shared/helpers/getLoginData';

const AppHeader: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Header className={styles.header}>
      <div className={styles.logoWrapper}>
        <img src="/assets/logo/logo.png" width={173} height={52} alt="logo" />
        <Typography className={styles.title}>Эффективный рабочий</Typography>
      </div>
      <div className={styles.textWrapper}>
        {isAuthenticated && (
          <>
            <div className={styles.logoWrapper}>
              <Typography component="p" className={styles.role}>
                Администратор системы
              </Typography>
              <Typography component="p" type="h1" className={styles.name}>
                {getLoginData().userName}
              </Typography>
            </div>
            <DataTime />
          </>
        )}
      </div>
    </Header>
  );
};
export default AppHeader;
