import { useEffect } from 'react';
import localForage from 'localforage';
import { useOnLogOutClick } from './useOnLogOutClick';
import getLoginData from '../helpers/getLoginData';

const useBeforeUnloadEvent = (): void => {
  const { onLogOutClick } = useOnLogOutClick();
  const loginData = getLoginData();

  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    window.addEventListener('unload', () => {
      navigator.serviceWorker.controller?.postMessage({
        type: 'UNMOUNT',
        token: loginData.token,
      });
    });

    localForage.getItem<string>('isClosed').then((isClosedLF) => {
      const isClosed = isClosedLF ? JSON.parse(isClosedLF) : null;
      if (isClosed) {
        localForage.removeItem('isClosed');
        onLogOutClick(false);
      }
    });

    return () => {
      window.removeEventListener('beforeunload', unloadCallback);
    };
  }, []);
};

export default useBeforeUnloadEvent;
