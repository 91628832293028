import React from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Outlet } from 'react-router-dom';
import SocketContext from '../context/SocketContext';
import createPath from '../helpers/createPath';
import { env } from '../../environments/env';
import DashboardProvider from './DashboardProvider';

const socketURL = env.REACT_APP_SOCKET_URL;

const SocketProvider: React.FC = () => {
  const socket = new ReconnectingWebSocket(createPath(socketURL, 'info/'));

  React.useEffect(() => {
    socket.onopen = (): void => {
      console.log('Connected');
    };

    return () => {
      socket.close();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      <DashboardProvider>
        <Outlet />
      </DashboardProvider>
    </SocketContext.Provider>
  );
};

export default SocketProvider;
